import React from "react"
import {graphql, useStaticQuery} from 'gatsby'
import styled from 'styled-components'
import Image from "gatsby-image";

const CompanyInfoCore = ({className}) => {
  const data = useStaticQuery(query)

  return (
    <div className={className}>
      <Image className={`${className}__logo`} fluid={data.logo.childImageSharp.fluid}/>
      <p className={`${className}__text`}>LandBank is a group of finance professionals with diverse nationalities and backgrounds. We provide optimal business solutions both in Japan and overseas.</p>
      <p className={`${className}__address`}>
        New Otani Garden Court 12F,<br/>
        4-1 Kioicho, Chiyoda-ku,<br/>
        Tokyo, Japan 102-0094<br/>
        Tel: (+81)3-4510-3395<br/>
        www.lbkk.co.jp
      </p>
      <div className={`${className}__licenses`}>
        <p className={`${className}__license`}>
          第二種金融商品取引業 投資助言・代理業<br/>
          関東財務局長(金商)第1260号
        </p>
        <p className={`${className}__license`}>
          一般社団法人日本投資顧問業協会会員<br/>
          宅地建物取引業 東京都知事免許(4)第81747号
        </p>
      </div>
    </div>
)}

const CompanyInfo = styled(CompanyInfoCore)`
  &__logo {
    max-width: 168px;
    margin-bottom: 24px;
  }
  
  &__text,
  &__address {
    margin-bottom: 1em;
    padding-bottom: 12px;
    border-bottom: thin solid #eee;
  }
  
  &__licenses {
  }
  
  &__license {
    margin-bottom: 1em;
  }
`

export const query = graphql`
  query CompanyInfoQuery {
    logo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

export default CompanyInfo
