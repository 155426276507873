import React from "react"
import Seo from "../components/Seo/seo"
import Layout from "../components/Layout/layout";
import {graphql, Link, useStaticQuery} from 'gatsby'
import styled from 'styled-components'
import PageBody from "../components/PageBody/pageBody";
import CompanyInfo from "../components/CompanyInfo/company-info";
import media from 'styled-media-query'
import Image from "gatsby-image";

const TwoColumns = styled.div`
  display: flex;
  
  ${media.lessThan('medium')`
    display: block;
  `}

  > * {
    :last-child {
      flex: 1 1 auto;
    }
    
    :first-child {
      flex: 0 1 500px;
      margin-right: 36px;
      padding: 0 0 0 12px;
      
      ${media.lessThan('medium')`
        margin-right: 0;
      `}
    }
  }
`

const Map = styled(({className}) => (
  <iframe className={className}
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.8304795626322!2d139.73268331535067!3d35.681175880194374!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188c7ce48bdbf3%3A0x5e8b1c1d41cec18d!2z44CSMTAyLTAwOTQg5p2x5Lqs6YO95Y2D5Luj55Sw5Yy657SA5bC-5LqV55S677yU4oiS77yR!5e0!3m2!1sja!2sjp!4v1534295407515"
    width="1000" height="250" frameBorder="0" allowFullScreen/>
))`
  margin-top: 24px;
  width: 100%;
  height: 480px;
`

const ContactUs = () => {
  const data = useStaticQuery(query)

  return (
    <>
      <Seo slug=""/>
      <Layout>
        <PageBody h1="Contact Us">
          <TwoColumns>
            <CompanyInfo/>
            <div>
              <Image className="logo" fluid={data.map.childImageSharp.fluid}/>
            </div>
          </TwoColumns>
          <Map/>
        </PageBody>
      </Layout>
    </>
  )
}

export const query = graphql`
  query ContactUsQuery {
    map: file(relativePath: { eq: "map.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

// export default ContactUs
export default () => {
    if (typeof window !== 'undefined') {
        window.location = '/';
    }

    return null;
}
